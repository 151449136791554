/**,*/
/**::before,*/
/**::after {*/
/*  box-sizing: border-box;*/
/*}*/

/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*  sans-serif;*/
/*  !*font-family: 'Poppins', sans-serif;*!*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-rendering: optimizeSpeed;*/
/*  line-height: 1.5;*/
/*  min-height: 100vh;*/
/*}*/

